import React from "react";
import Layout from "../components/Layout/Layout.js";
import styles from './index.module.css';
import {Helmet} from 'react-helmet';
import classnames from 'classnames';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from "gatsby";
import { FaAngleRight } from 'react-icons/fa'
import 'animate.css';


export default function Home(){
    const { file } = useStaticQuery(query);
    const BoxClasses = classnames(styles.heroBox, 'animated', 'flipInX');
    const SubTextClasses = classnames(styles.heroSubText, 'animated', 'slideInLeft', 'delay-2s')
    return(
        <Layout style={{alignItems: 'flex-start'}} centered={true}>
            <Helmet>
                <title>Home | Circa Wallcoverings</title>
                <meta name="Description" content="Bold designs for today's most stylish spaces."/>
            </Helmet>
            <div className={styles.contentContainer}>
                    <Img fluid={file.childImageSharp.fluid} className={styles.bgImage} style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}/>
                <div className={styles.heroContent}>
                    <div className={BoxClasses}> 
                        <h1 className={styles.heroText}>BOLD DESIGNS FOR TODAYS MOST STYLISH SPACES</h1>
                        <div className={SubTextClasses}>
                            <a href='/shop' style={{color: '#E8B2B2'}}>SHOP THE COLLECTIONS <FaAngleRight/></a>
                        </div>
                    </div>
                </div>
            </div>
            
        </Layout>
    )
}
const query = graphql`
  query {
    file(relativePath: {eq: "images/home/HomeHero.jpg"}){
        childImageSharp {
            fluid(maxWidth: 3200) {
              ...GatsbyImageSharpFluid
                }
            }
        }
    }`
